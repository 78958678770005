@import 'import';
.PortChangeMessageSection {
  background-color: $blue-light;

  .portText {
    display: flex;
    flex-direction: column;
  }

  .portHeading {
    height: auto;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
  }

  .body {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: $grey-text;
  }

  &.details-module {
    min-height: rem(68);
    margin: 0 22.5px 40px;
    padding: 10px 24px;

    @include media-breakpoint-up(lg) {
      margin: 0 50px 40px;
    }
  }

  &.flyout {
    margin: 0 var(--view-more-dates-inline-padding, 25px) 40px;
    padding: 10px 24px;
  }

  &.under-package-card {
    width: 100%;
    padding: 4px 10px;
    text-align: center;

    @include font($size: rem(11), $line-height: rem(16));

    .portText {
      display: inline;
    }

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
}

.highlightedPort {
  font-weight: 500;
}

.strike-trough {
  text-decoration: line-through;
}
